*{
    padding:0;
    margin:0;
    /*border: 1px solid red;*/
}
html { 
    font-family: 'Roboto', sans-serif;
    height:100%; 
    padding:0;
    margin:0;
}
body{
    height:100%;
}
#root{
    height:100%;
}